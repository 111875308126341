import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f924448c&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppPageHeader: require('/home/vsts/work/1/a/NuxtApp/components/app/PageHeader.vue').default,FormLogin: require('/home/vsts/work/1/a/NuxtApp/components/form/Login.vue').default,FormRegister: require('/home/vsts/work/1/a/NuxtApp/components/form/Register.vue').default})
